export default {
  path: '/',
  component: () => import(/* webpackChunkName: "print" */ '@/layouts/print-layout.vue'),
  children: [
    {
      path: '/print',
      name: 'formulir-print',
      meta: { title: 'Cetak Formulir' },
      component: () =>
        import('@/views/print/index-page.vue'),
    },
    {
      path: '/form/:token',
      name: 'guest-form',
      meta: { title: 'Input Formulir' },
      component: () =>
        import('@/views/guest-form/form-page.vue'),
    },
    {
      path: '/form/:token/success',
      name: 'guest-form-success',
      meta: { title: 'Formulir Tersimpan' },
      component: () =>
        import('@/views/guest-form/success-page.vue'),
    },
  ],
}