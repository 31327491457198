export default {
  path: '/',
  component: () => import(/* webpackChunkName: "auth" */ '@/layouts/auth-layout.vue'),
  children: [
    {
      path: '/login',
      name: 'login',
      meta: { title: 'Login' },
      component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/index-page.vue'),
    },
  ],
}