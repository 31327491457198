import { createRouter, createWebHistory } from 'vue-router'
import authRoutes from './auth'
import printRoutes from './print'
const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/layouts/main-layout.vue'),
    children: [
      // {
      //   path: '/',
      //   name: 'dashboard',
      //   meta: { title: 'Dashboard' },
      //   component: () =>
      //     import('@/views/dashboard/index-page.vue'),
      // },  //without dashboard for now
      {
        path: '/',
        name: 'formulir',
        meta: { title: 'Formulir Evaluasi Contractor Fitness To Work' },
        component: () =>
          import('@/views/evaluation-form/index-page.vue'),
      },
      {
        path: '/create',
        name: 'formulir-create',
        meta: { title: 'Buat Formulir Baru' },
        component: () =>
          import('@/views/evaluation-form/form-page.vue'),
      },
      {
        path: '/form-guest',
        name: 'formulir-guest',
        meta: { title: 'Formulir Manual' },
        component: () =>
          import('@/views/guest-form/index-page.vue'),
      },
      {
        path: '/:form_id/edit',
        name: 'formulir-edit',
        meta: { title: 'Edit Formulir' },
        component: () =>
          import('@/views/evaluation-form/form-page.vue'),
      },
      {
        path: '/:token/edit',
        name: 'formulir-guest-edit',
        meta: { title: 'Edit Formulir Manual' },
        component: () =>
          import('@/views/evaluation-form/form-page.vue'),
      },
    ],
  },
  authRoutes,
  printRoutes,
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
